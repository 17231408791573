export default function kycOnBoardingReducer(state = {}, action) {

    switch (action.type) {
        case 'LODER_FOR_STATUS_LIST':
            return { ...state, leadStatusListLoader: action.payload }
        case 'LODER_FOR_PRODUCT_LIST':
            return { ...state, leadProductListLoader: action.payload }
        case 'LEAD_LOADER':
            return { ...state, leadLoader: action.payload }
        case 'GOT_STATUS_LIST':
        case 'FAILED_STATUS_LIST':
            return { ...state, leadStatusList: action.payload }
        case 'SUCCESS_ADD_COMMENT':
        case 'FAILED_ADD_COMMENT':
            return { ...state, leadAddComment: action.payload }
        case 'GOT_PRODUCT_LIST':
        case 'FAILED_PRODUCT_LIST':
            return { ...state, leadProductList: action.payload }
        case 'GOT_COUNTRY_CODE':
        case 'FAILED_COUNTRY_CODE':
            return { ...state, countryCode: action.payload }
        case 'GET_LEADS_TABLE_DATA':
        case 'FAILED_LEADS_TABLE_DATA':
            return { ...state, leadsTableData: action.payload }
        case 'SUCCESS_UPDATE_LEAD_STATUS':
        case 'FAILED_UPDATE_LEAD_STATUS':
            return { ...state, leadStatusUpdate: action.payload,leadNotifications:action.payload  }
        case 'SUCCESS_BULK_UPDATE':
        case 'FAILED_BULK_UPDATE':
            return { ...state, leadBulkUpdate: action.payload,leadNotifications:action.payload  }
        case 'SUCCESS_CREATE_LEAD':
        case 'FAILED_CREATE_LEAD':
            return { ...state, createLead: action.payload,leadNotifications:action.payload  }
        case 'GOT_COMMENT_LIST':
        case 'FAILED_COMMENT_LIST':
            return { ...state, leadCommentList: action.payload }
        case 'SUCCESS_CONVERT_LEAD_TO_CLIENT':
        case 'FAILED_CONVERT_LEAD_TO_CLIENT':
            return { ...state, convertLeadToClient: action.payload ,leadNotifications:action.payload }
        case 'LEAD_LOADER_FORM':
            return { ...state, formLoaderForLead:action.payload }
        case 'LEAD_NOTIFICATIONS':
            return { ...state, leadNotifications: action.payload }
    }
    return state
}