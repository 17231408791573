import crypto from 'crypto'
import { ENCRYPTION_UTIL } from 'app/constants/shared/commonConst'

export const EncryptData = (plainData) => {
	if(plainData && typeof plainData !== "object") {
	  const iv = Buffer.from(ENCRYPTION_UTIL.encryptionSecretKey, ENCRYPTION_UTIL.ENCODING)
	  const key = crypto.createHash('md5').update(ENCRYPTION_UTIL.encryptionSecretKey).digest()
	  const keys = crypto.createCipheriv(ENCRYPTION_UTIL.ALGO, key, iv)
	  let encryptedText = keys.update(plainData.toString(), ENCRYPTION_UTIL.ENCODING, ENCRYPTION_UTIL.DIGEST)
	  encryptedText += keys.final(ENCRYPTION_UTIL.DIGEST)
	  return encryptedText
	}
	return plainData
  }
export const getCookie = (name) => {
	name = name + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let checkCookie = decodedCookie.split(name);
	let tokenCookie = checkCookie[1] && checkCookie[1].split('.')
	return tokenCookie
}

export const decryptMobileData = (encryptedData, encryptedKey, iv) => {
	if (!encryptedData || !encryptedKey || !iv) {
		return
	}
	const encryptedKeyBuffer = Buffer.from(encryptedKey, 'base64')
	const ivBuffer = Buffer.from(iv, 'base64')
	const aesKey = crypto.privateDecrypt(process.env.OTP_PRIVATE_KEY, encryptedKeyBuffer)
	const decipher = crypto.createDecipheriv('aes-128-cbc', aesKey, ivBuffer)
	let decryptedData = decipher.update(encryptedData, 'base64', 'utf8')
	decryptedData += decipher.final('utf8')
	return JSON.parse(decryptedData)
}