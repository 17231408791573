var intialState = {brokerSummary:{},investmentDayWise:{},newClientsList:{},topClientsList:{},purchaseTxnsGraph:{}};
export default function brokerDashboard(state=intialState,action){
	switch(action.type){
		case 'GETTING_BROKER_DASHBOARD_SUMMARY':
			return{...state,status:"Getting Client Summary"}
		case 'COMPONENT_LOADER_IFA_SUMMARY':
			return{...state,summaryLoader:action.payload}
		case 'GOT_BROKER_DASHBOARD_SUMMARY':
			return{...state,brokerSummary:action.payload,status:null}
		case 'FAILED_BROKER_DASHBOARD_SUMMARY':
			return{...state,status:"Unable To fetch Client Summary"}
		case 'GETTING_INVESTMENT_DAY_WISE':
			return{...state,status:"Getting Client Summary"}
		case 'COMPONENT_LOADER_IFA_GRAPH':
			return{...state, ifaGraphLoader: action.payload}
		case 'GOT_INVESTMENT_DAY_WISE':
			return{...state,investmentDayWise:action.payload}
		case 'FAILED_INVESTMENT_DAY_WISE':
			return{...state,status:"Unable To fetch Client Summary"}
		case 'GETTING_NEW_CLIENTS':
			return{...state,status:"Getting Client Summary"}
		case 'COMPONENT_LOADER_NEW_CLIENTS':
			return{...state,newClientsLoader:action.payload}
		case 'GOT_NEW_CLIENTS':
			return{...state,newClientsList:action.payload}
		case 'FAILED_NEW_CLIENTS':
			return{...state,status:"Unable To fetch Client Summary"}
		case 'GETTING_TOP_CLIENTS':
			return{...state,status:"Getting Client Summary"}
		case 'GOT_TOP_CLIENTS':
			return{...state,topClientsList:action.payload}
		case 'FAILED_TOP_CLIENTS':
			return{...state,status:"Unable To fetch Client Summary"}
		case 'GETTING_BROKER_PURCHASE_TXNS':
			return{...state,purchaseTxns:"Getting Client Summary"}
		case 'COMPONENT_LOADER_PURCHASE_REDUMPTION':
			return{...state, purchaseRedumptionTxns:action.payload}
		case 'GOT_BROKER_PURCHASE_TXNS':
			return{...state,purchaseTxns:action.payload}
		case 'FAILED_BROKER_PURCHASE_TXNS':
			return{...state,purchaseTxns:"Unable To fetch Client Summary"}
		case 'GETTING_BROKER_SELL_TXNS':
			return{...state,sellTxns:"Getting Client Summary"}
		case 'GOT_BROKER_SELL_TXNS':
			return{...state,sellTxns:action.payload}
		case 'FAILED_BROKER_SELL_TXNS':
			return{...state,sellTxns:"Unable To fetch Client Summary"}
		case 'GETTING_PURCHASE_TXNS_GRAPH':
			return{...state,purchaseTxnsGraph:"Getting Client Summary"}
		case 'GOT_PURCHASE_TXNS_GRAPH':
			return{...state,purchaseTxnsGraph:action.payload}
		case 'FAILED_PURCHASE_TXNS_GRAPH':
			return{...state,purchaseTxnsGraph:"Unable To fetch Client Summary"}

		case 'GOT_SYSTEM_DATA_ALERT':
			return{...state,systemAlertData:action.payload}
		case 'FAILED_SYSTEM_DATA_ALERT':
			return{...state,systemAlertData:action.payload}
		case 'COMPONENT_LOADER_SYSTEM_DATA_ALERT':
			return{...state,loaderAlertData:action.payload}

		case 'GOT_BUSINESS_DATA_UPDATE':
			return{...state,businessData:action.payload}
		case 'FAILED_BUSINESS_DATA_UPDATE':
			return{...state,businessData:action.payload}
		case 'COMPONENT_LOADER_BUSINESS_DATA_UPDATE':
			return {...state,loaderBusinessUpdate:action.payload}

		case 'GOT_SIP_MINING_DATA':
			return{...state,sipMiningData:action.payload}
		case 'FAILED_SIP_MINING_DATA':
			return{...state,sipMiningData:action.payload}
		case 'COMPONENT_LOADER_SIP_MINING_DATA':
			return{...state,loaderSipMining:action.payload}

		case 'CREDENTIAL_STATUS':
			return{...state,credentialStatus:action.payload}
		case 'FAILED_CREDENTIAL_STATUS':
			return{...state,credentialStatus:action.payload}
		case 'COMPONENT_LOADER_CREDENTIAL_STATUS':
			return{...state,loaderCredentialStatus:action.payload}

		case 'ARN_LAST_TXN_DATE':
			return{...state,arnLastTxnDate:action.payload}
		case 'FAILED_ARN_LAST_TXN_DATE':
			return{...state,arnLastTxnDate:action.payload}
		case 'COMPONENT_LOADER_ARN_LAST_TXN_DATE':
			return{...state,loaderArnLastTxn:action.payload}
  
		case 'GOT_CHECK_PROCESS_FOLIOS':
			return{...state,foliosProcessStatus:action.payload}
		case 'FAILED_CHECK_PROCESS_FOLIOS':
			return{...state,foliosProcessStatus:action.payload}   			
		case 'GOT_TOPIC_DETALS':
			return{...state,topicDetails:action.payload}
		case 'FAILED_TO_FETCH_LATEST_TOPIC_CODE':
		case 'FAILED_TO_GET_TOPIC_DETAILS':
			return{...state,topicDetailsFetchingFailure:action.payload}

		case 'GOT_ALERTS_COUNT':
		case 'FAILED_ALERTS_COUNT':
			return { ...state, alertsCount: action.payload }

		default:
      		new Error('No action type matched in Filter reducer')
	}
	return state;
}