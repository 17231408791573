import { combineReducers } from 'redux'
import userReducer from './user'
import dashboardReducer from './dashboard'
import filters from './dashboard/filters'
import clientDashboard from './client/dashboard'
import goalPlanner from './client/goalPlanner'
import config from './config'
import { reducer as formReducer } from 'redux-form'
import levelUsersReducer from './user/user'
import brokerDashboardReducer from './broker'
import brokerDashboard from './broker/dashboard'
import transactions from './shared/transaction'
import overlay from './shared/overlay'
import payments from './shared/payments'
import utils from './shared/utils'
import folioLookup from './broker/folioLookup'
import adminSearchReducers from './admin'
import adminFormsReducers from './admin/adminFormsReducers'
import investOnline from './broker/investOnline'
import handleExceptionReducer from 'app/reducers/admin/handleExceptionReducer'
import businessAnalytics from './broker/businessAnalytics'
import accountSettings from 'app/reducers/broker/accountSettings'
import investOnlineClient from 'app/reducers/client/investOnline'
import cart from 'app/reducers/client/cart'
import myDocumentList from 'app/reducers/client/myDocuments'
import mySystematicTxns from 'app/reducers/client/mySystematicTransactions'
import folioLookupClient from 'app/reducers/client/folioLookup'
import aumDeleteAllFoliosTxns from 'app/reducers/shared/aumDeleteFolioTxns'
import capitalGainRealizedPDF from 'app/reducers/client/capitalGainRealized'
import reportAdmin from 'app/reducers/admin/report/reportReducer'
import rollingReturns from 'app/reducers/broker/rollingReturns'
import taskManagement from 'app/reducers/admin/taskManagement'
import taskManager from 'app/reducers/broker/taskManager'
import complianceReport from 'app/reducers/broker/complianceReport'
import calculator from 'app/uiCollection/calculator/reducer'
import deleteBroker from 'app/reducers/admin/deleteBrokerReducers'
import bseInvestOnline from 'app/reducers/client/bseInvestments'
import iMail from 'app/reducers/broker/iMailReducers'
import investOnlineBse from 'app/reducers/InvestOnline/BseCreation'
import SOAJob from 'app/reducers/broker/SOAjob'
import PossibleDuplicates from 'app/reducers/broker/possibleDuplicates'
import insuranceReducer from 'app/reducers/shared/insurance'
import clientReducer from 'app/reducers/client'
import utilityReducer from 'app/reducers/broker/utilityReducers'
import brokerage from 'app/reducers/broker/brokerageReducers'
import securityExposureReducer from 'app/reducers/shared/securityExposure'
import portfolioScreenerReducer from 'app/reducers/broker/portfolioScreener'
import adminOrders from 'app/reducers/admin/ordersReducers'
import factsheetReducer from 'app/reducers/public/factsheet'
import investOnlineMfu from 'app/reducers/InvestOnline/MfuCreation'
import emailLogs from 'app/reducers/broker/emailLogs'
import casImportReducer from 'app/reducers/broker/casImportReducer'
import componentLoaders from 'app/reducers/shared/componentLoaders'
import investOnlineReducer from 'app/reducers/InvestOnline';
import fileStatusReducer from 'app/reducers/admin/fileStatusReducer';
import clientBillingStore from 'app/reducers/broker/clientBilling'
import riskProfilingReducer from 'app/reducers/broker/riskProfilingReducer';
import riskProfilerClient from 'app/reducers/client/riskProfiler';
import transactionsViewReducer from 'app/reducers/broker/transactionsViewReducer';
import dividendReportData from 'app/reducers/client/dividendReport'
import onBoardingReducer from 'app/reducers/onBoarding'
import onboardingMFUReducer from 'app/reducers/onBoarding/onboardingMFU'
import onboardingBseReducer from 'app/reducers/onBoarding/onboardingBse'
import topSchemesReducer from 'app/reducers/shared/topSchemes';
import createCredential from 'app/reducers/admin/createCredential';
import userDefinedSecuritiesReducer from 'app/reducers/broker/userDefinedSecuritiesReducer'
import Login from './user'
import kycOnBoardingReducer from '../reducers/kycOnBoarding'
import leads from '../reducers/Leads'

export default combineReducers({
  form:formReducer,
  userReducer,
  dashboardReducer,
  filters,
  config,
  levelUsersReducer,
  clientDashboard,
  brokerDashboardReducer,
  brokerDashboard,
  goalPlanner,
  transactions,
  folioLookup,
  adminSearchReducers,
  adminFormsReducers,
  investOnline,
  overlay,
  utils,
  handleExceptionReducer,
  businessAnalytics,
  accountSettings,
  investOnlineClient,
  cart,
  payments,
  myDocumentList,
  mySystematicTxns,
  folioLookupClient,
  aumDeleteAllFoliosTxns,
  capitalGainRealizedPDF,
  reportAdmin,
  rollingReturns,
  taskManagement,
  taskManager,
  calculator,
  deleteBroker,
  complianceReport,
  bseInvestOnline,
  iMail,
  investOnlineBse,
  SOAJob,
  PossibleDuplicates,
  insuranceReducer,
  utilityReducer,
  brokerage,
  clientReducer,
  securityExposureReducer,
  portfolioScreenerReducer,
  adminOrders,
  factsheetReducer,
  investOnlineMfu,
  emailLogs,
  casImportReducer,
  componentLoaders,
  investOnlineReducer,
  fileStatusReducer,
  clientBillingStore,
  riskProfilingReducer,
  riskProfilerClient,
  transactionsViewReducer,
  dividendReportData,
  onBoardingReducer,
  onboardingMFUReducer,
  onboardingBseReducer,
  topSchemesReducer,
  createCredential,
  Login,
  kycOnBoardingReducer,
  leads,
  userDefinedSecuritiesReducer
})
