var intialState = {
    aumReport:{},
    arnsList: {},
    fundsList: {},
    schemesList: {},
    categoryList: {},
    sipDetails: {},
};
    
export default function dashboard(state=intialState,action){
switch (action.type) {

  
  case 'GETTING_AUM_REPORTS_DATA':
    return {...state,}
  case 'GOT_AUM_REPORTS_DATA':
    return {...state, aumReport:action.payload}
  case 'COMPONENT_LOADER_AUM_REPORT':
    return {...state ,loaderAumReport:action.payload}
  case 'FAILED_AUM_REPORTS_DATA':
      return {...state, aumReport:{errorMsg:action.payload}}

  case 'GETTING_ARNS_LIST_DATA':
    return {...state,}
  case 'GOT_ARNS_LIST_DATA':
    return {...state, arnsList:action.payload}
  case 'COMPONENT_LOADER_ARN_LIST':
    return{...state,loaderArn:action.payload}
  case 'FAILED_ARNS_LIST_DATA':
      return {...state, arnsList:{errorMsg:action.payload} }

  case 'GETTING_FUNDS_LIST_DATA':
    return {...state }
  case 'GOT_FUNDS_LIST_DATA':
    return {...state, fundsList:action.payload}
  case 'COMPONENT_LOADER_FUND_LIST':
    return{...state,loaderArn:action.payload}
  case 'FAILED_FUNDS_LIST_DATA':
      return {...state, fundsList:{errorMsg:action.payload} }

  case 'GETTING_SCHEMES_LIST_DATA':
    return {...state}
  case 'GOT_SCHEMES_LIST_DATA':
    return {...state, schemesList:action.payload}
  case 'COMPONENT_LOADER_SCHEMES_LIST':
    return{...state,loaderArn:action.payload}
  case 'FAILED_SCHEMES_LIST_DATA':
      return {...state, schemesList:{errorMsg:action.payload} }

  case 'GETTING_CATEGORY_LIST_DATA':
    return {...state}
  case 'GOT_CATEGORY_LIST_DATA':
    return {...state, categoryList:{data:action.payload}}
  case 'COMPONENT_LOADER_CATEGORY_LIST':
    return{...state,loaderArn:action.payload}
  case 'FAILED_CATEGORY_LIST_DATA':
      return {...state, categoryList:{errorMsg:action.payload } }

  case 'GETTING_SIP_DETAILS_DATA':
    return {...state}
  case 'GOT_SIP_DETAILS_DATA':
    return {...state, sipDetails:action.payload}
  case 'COMPONENT_LOADER_SIP_MINING':
    return {...state, loaderSipMining:action.payload}
  case 'FAILED_SIP_DETAILS_DATA':
      return {...state, sipDetails:{errorMsg:action.payload } }
  
case 'UPDATE_SIP_TRANSACTION':
  return {...state, notificationData: action.payload }
case 'FAILED_TO_UPDATE_SIP_TRANSACTION':
  return {...state, notificationData: action.payload } 

case 'GET_ADVANCED_SIP_DETAILS':
  return {...state, advanceSIPDetailsData: action.payload }
case 'FAILED_ADVANCED_SIP_DETAILS':
  return {...state, advanceSIPDetailsData: action.payload }   

  case 'GETTING_SIP_SUMMARY_DATA':
    return {...state}
  case 'GOT_SIP_SUMMARY_DATA':
    return {...state, sipSummary:action.payload}
  case 'COMPONENT_LOADER_SIP_MINING_SUM':
    return {...state, loaderSipMining:action.payload}
  case 'FAILED_SIP_SUMMARY_DATA':
      return {...state, sipSummary:{errorMsg:action.payload } }

  case 'ADD_SIP_MINING_FILTERS': 
    return {...state, sipMiningFilters: action.payload } 

  case 'GOT_CASH_FLOW_LIST_DATA':
    return {...state, cashFlowList:action.payload}
  case 'COMPONENT_LOADER_CASH_FLOW_LIST':
    return {...state, loaderCashFlowList:action.payload}
  case 'FAILED_CASH_FLOW_LIST_DATA':
      return {...state, cashFlowListError:action.payload}

  case 'GOT_RTAS':
    return {...state, rtaList:action.payload}
  case 'FAILED_RTAS':
    return {...state, rtaList:action.payload}
  
  case 'GOT_CASH_FLOW_SHARE_BOND_DATA':
    return {...state, cashFlowList:action.payload}
  case 'COMPONENT_LOADER_CASH_FLOW_SHARE_BOND_LIST':
    return {...state, loaderCashFlowList:action.payload}
  case 'FAILED_CASH_FLOW_SHARE_BOND_DATA':
      return {...state, cashFlowListError:action.payload}

  case 'GOT_AUM_REPORTS_MUTUAL_FUND_DATA':
    return {...state, aumReport:action.payload}
  case 'COMPONENT_LOADER_AUM_REPORTS_MUTUAL_FUND':
    return {...state ,loaderAumReport:action.payload}
  case 'FAILED_AUM_REPORTS_MUTUAL_FUND_DATA':
      return {...state, aumReport:action.payload}

  case 'GOT_AUM_REPORT_SHARE_BOND_DATA':
    return {...state, aumReportShareBondData:action.payload}
  case 'COMPONENT_LOADER_AUM_REPORT_SHARE_BOND':
    return {...state ,loaderAumReport:action.payload}
  case 'FAILED_AUM_REPORT_SHARE_BOND_DATA':
      return {...state, aumReportShareBondData:action.payload}

  case 'COMPONENT_LOADER_GET_ALERT_REPORTS_DATA':
    return {...state, loaderAlertReports: action.payload}

  case 'GOT_ALERT_REPORT_DATA':
    return {...state,alertReportsData:action.payload}

  case 'FAILED_TO_GET_ALERT_REPORT_DATA':
    return {...state, alertReportsData:action.payload}
     
  case 'TABLE_HEADING_DATA':
    return{...state,tableHeadingData:action.payload}

  case 'COMPONENT_LOADER_CHANGE_BROKER':
    return{...state,changeBrokerLoader:action.payload}

  case 'GOT_CHANGE_BROKER_DATA':
    return{...state,changeBrokerData:action.payload}

  case 'FAILED_CHANGE_BROKER_DATA':
    return{...state,changeBrokerData:action.payload}

  case 'SET_CEASE_DATE_SUCCESSFULLY': 
    return {...state, notificationData: action.payload }

  case 'FAILED_TO_SET_CEASE_DATE': 
    return {...state, notificationData: action.payload }

  case 'COMPONENT_LOADER_AUM_CATEGORY':
    return {...state, loaderAumCategory:action.payload}

  case 'GOT_AUM_REPORT_CATEGORY_DATA':
    return {...state, aumReportCategoryData:action.payload}

  case 'FAILED_AUM_REPORT_CATEGORY_DATA':
    return {...state, aumReportCategoryData:action.payload}

  case 'CLEAR_NOTIFICATION': 
    return {...state, notificationData: null }

  case 'NEW_MERGED_GROUPBY':
    return {...state, mergedGroupBy:action.payload}
  case 'SET_LOWER_REPORTING_INFO':
    return {...state, lowerReportingInfo:action.payload }

  case 'ADD_SIP_BUSINESS_REPORT_FILTERS':
    return {...state, sipBusinessFilters:action.payload }

  case 'GOT_SIP_BUSINESS_REPORT_DATA':
    return {...state, sipBusinessReportData: action.payload }
  case 'FAILED_TO_GET_SIP_BUSINESS_REPORT_DATA':
    return { ...state, sipBusinessReportData: null }

  case 'ADD_AUM_REPORT_FILTERS':
    return { ...state, aumReportFilters: action.payload }

  case 'GOT_AUM_REPORT_DATA':
    return { ...state, aumReportData: action.payload }
  case 'FAILED_TO_GET_AUM_REPORT_DATA':
    return { ...state, aumReportData: [] }

  case 'SENT_ALERT_MAIL_SUCCESSFULLY':
    return{ ...state, notificationData: action.payload }
  case 'FAILED_TO_SEND_ALERT_MAIL':
    return{ ...state, notificationData: action.payload }

  case 'ADD_REDEMPTION_SIMULATION_FILTERS':
    return { ...state, redemptionSimulationFilters: action.payload }

  case 'GOT_REDEMPTION_SIMULATION_DATA':
    return { ...state, redemptionSimulationData: action.payload }
  case 'FAILED_TO_GET_REDEMPTION_SIMULATION_DATA':
    return { ...state, redemptionSimulationData: {} }


  case 'INACTIVE_CLIENTS_TABLE_CUSTOM_DATA':
    return { ...state, inactiveCustomData: action.payload }

  case 'GOT_INACTIVE_CLIENTS_DATA':
    return {...state, inactiveClientsList: action.payload}

  case 'FAILED_TO_GET_INACTIVE_CLIENTS_DATA':
    return {...state, inactiveClientsList: action.payload}

  case 'ADD_INACTIVE_CLIENTS_FILTERS':
    return {...state, inactiveFilters: action.payload}

  case 'INACTIVE_CLIENTS_STORED_PARAMS':
    return {...state, inactiveStoredParams: action.payload && action.payload.params,  inactiveStoreFilters: action.payload && action.payload.filters}
   
  case 'GOT_SIP_PROCUREMENT_DATA':
    return {...state, sipProcurementList: action.payload}
    
  case 'FAILED_SIP_PROCUREMENT_DATA':
    return {...state, sipProcurementList: action.payload}

  case 'ADD_MISSING_SIP_FILTER': 
      return {...state, missingSipFilter: action.payload } 

  case 'GOT_ELSS_PROCUREMENT_REPORT_DATA':
    return {...state, elssProcurementList:action.payload}
  case 'FAILED_ELSS_PROCUREMENT_REPORT_DATA':
    return {...state, elssProcurementList:action.payload}
  
  case 'ADD_ELSS_PROCUREMENT_REPORT_FILTER':
    return {...state,elssProcurementFilters:action.payload}

  case 'GET_CLIENT_ACQUISITION_REPORT':
    return {...state, clientAcquisitionReport:action.payload} 
  case 'FAILED_CLIENT_ACQUISITION_REPORT':
    return {...state, clientAcquisitionReport:action.payload}  
 
  case 'GOT_CROSS_SELL_REPORT_DATA':
    return {...state, crossSellList:action.payload}
  case 'FAILED_CROSS_SELL_REPORT_DATA':
    return {...state, crossSellList:action.payload}

    case 'GOT_SWO_WITHOUT_SWI_REPORT_DATA':
    return {...state, swoWithoutSwiReport:action.payload}
  case 'FAILED_SWO_WITHOUT_SWI_REPORT_DATA':
    return {...state, swoWithoutSwiReport:action.payload}

  case 'GET_SCHEME_COMPARISION_REPORT' :
    return {...state, schemeComparisionReport:action.payload}
  case 'FAILED_SCHEME_COMPARISION_REPORT':
    return {...state, schemeComparisionReport:action.payload}

  case 'GOT_AMFI_DUE_DILIGENCE_DATA' :
    return {...state, amfiDueDiligenceReport:action.payload}
  case 'FAILED_AMFI_DUE_DILIGENCE_DATA':
    return {...state, amfiDueDiligenceReport:action.payload}  
    
  case 'GOT_MF_CLIENT_LIST':
    return { ...state, mfClientList: action.payload }
  case 'FAILED_MF_CLIENT_LIST':
    return { ...state, mfClientList: action.payload } 

  case 'CREATE_MF_TXN_CAMPAIGN':
    return { ...state, createMfTxn: action.payload }
  case 'FAILED_MF_TXN_CAMPAIGN_CREATION':
    return { ...state, createMfTxn: action.payload }   

  case 'SYSTEMATIC_RECORD_ADDED' :
  case 'FAILED_TO_ADD_SYSTEMATIC_RECORD' :
    return { ...state, systematicRecordData: action.payload ,notificationData: action.payload }

  case 'GOT_ALERTS_LIST':
  case 'ALERTS_LIST_FAILED':
    return { ...state, alertsList: action.payload }

    default:
      new Error('No action type matched in reducer')
  }
 
 return state
}
